// packs/forms.js
window.numbersOnly = numbersOnly;

function numbersOnly(input) {
  input.value = input.value.replace(/[^\d]/, "");
}

window.formatAmount = formatAmount;

function formatAmount(input) {
  let value = input.value;
  if (value != "") {
    input.value = parseFloat(value.replace(/,/g, ""))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

window.insertComma = insertComma;

function insertComma(input) {
  let value = input.value.replace(/,/g, "");
  const validValue = value.match(/^[1-9]\d*(?:\.\d{0,2})?$/);
  const valueWithComma = validValue
    ? validValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : null;
  input.value = valueWithComma;
}

window.computeAmount = computeAmount;

function computeAmount(input) {
  const value = input.value;
  const parsedValue = parseFloat(value.replace(/,/g, ""));
  if (value != "") {
    if (input == document.getElementById("amount")) {
      const amountReceived = (parsedValue - parsedValue * 0.039 - 15)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (parsedValue > 20.0) {
        document.getElementById("amount_received").value = amountReceived;
      } else {
        document.getElementById("amount_received").value = "0.00";
      }
    } else {
      const amountCharged = (parsedValue + parsedValue * 0.039 + 15)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      document.getElementById("amount").value = amountCharged;
    }
  } else {
    document.getElementById("amount_received").value = "";
    document.getElementById("amount").value = "";
  }
}

window.switchNotes = switchNotes;

function switchNotes() {
  let notes = document.getElementsByClassName("note");
  if (notes.length == 2) {
    if (notes[0].style.display == "block") {
      setTimeout(function () {
        notes[0].style.display = "none";
        notes[1].style.display = "block";
      }, 1000);
    } else {
      notes[0].style.display = "block";
      notes[1].style.display = "none";
    }
  }
}
