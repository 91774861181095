// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Stylesheets
import("styles/application.css");

// Date Picker
import datepicker from "js-datepicker";
window.datepicker = datepicker;

// Copy To Cliboard
import copy from "copy-to-clipboard";
window.copy = copy;

// Chart.js
// require("chart.js")

// Service Worker
require("packs/serviceworker-companion.js");

// Local Packs
require("packs/alerts.js");
require("packs/datepicker.js");
require("packs/download.js");
require("packs/faqs.js");
require("packs/forms.js");
require("packs/links.js");
require("packs/splash.js");
require("packs/success.js");
require("packs/terms.js");
require("packs/vouchers.js");
