// packs/terms.js
window.toggleTermsDisplay = toggleTermsDisplay;

function toggleTermsDisplay() {
  let terms = document.getElementById("terms");
  if (!!terms) {
    if (terms.style.display == "none") {
      terms.style.display = "block";
    } else {
      terms.style.display = "none";
    }
  }
}
