window.copyPaymentLink = copyPaymentLink;

function copyPaymentLink(link) {
    copy(link);
    createAlert("Your payment link has been copied to your clipboard.");
}

window.copyInviteLink = copyInviteLink;

function copyInviteLink(link) {
    copy(link);
    createAlert("Your invite link has been copied to your clipboard.");
}