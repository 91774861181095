// packs/alerts.js
function checkAlerts() {
  for (i = 0; i < document.getElementsByClassName("alert").length; i++) {
    if (document.getElementsByClassName("alert")[i].style.display != "none") {
      hideAlert(i);
    }
  }
}

function hideAlert(i) {
  setTimeout(function () {
    if (!!document.getElementsByClassName("alert")[i]) {
      document.getElementsByClassName("alert")[i].style.display = "none";
    }
  }, 4600);
}

checkAlerts();
setInterval(function () {
  checkAlerts();
}, 4800);

window.createAlert = createAlert;

function createAlert(message) {
  let alert = document.createElement("p");
  let messageContent = document.createTextNode(message);
  alert.className = "alert";
  alert.appendChild(messageContent);
  document.body.appendChild(alert);
}
