window.splash = splash;

function splash() {
  let splashScreen = document.getElementById("splash");
  if (sessionStorage.getItem("splashStatus") === "done") {
    if (!!splashScreen) {
      splashScreen.classList.remove("hidden");
      splashScreen.classList.add("hidden");
    }
  } else {
    setTimeout(() => {
      if (!!splashScreen) {
        splashScreen.classList.remove("clip-out");
        splashScreen.classList.add("clip-out");
      }
    }, 500);

    setTimeout(() => {
      if (!!splashScreen) {
        splashScreen.classList.remove("hidden");
        splashScreen.classList.add("hidden");
      }
    }, 1500);

    sessionStorage.setItem("splashStatus", "done");
  }
}
