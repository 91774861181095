if (navigator.serviceWorker) {
  navigator.serviceWorker.register("/serviceworker.js", { scope: "./" });
}

window.unregisterServiceworker = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .getRegistration("/serviceworker.js")
      .then((registration) => {
        if (registration) {
          // The service worker is registered, we can proceed with the unregistration.
          registration
            .unregister()
            .then((isUnregistered) => {
              if (isUnregistered) {
                console.log("Service Worker unregistered successfully.");
              } else {
                console.error("Service Worker unregistration failed.");
              }
            })
            .catch((error) => {
              console.error("Service Worker unregistration error:", error);
            });
        } else {
          console.log("No service worker is currently registered.");
        }
      })
      .catch((error) => {
        console.error(
          "Error while getting service worker registration:",
          error
        );
      });
  }
};
