document.addEventListener("DOMContentLoaded", function () {
  const faqs = document.querySelectorAll(".faq");
  const icons = document.querySelectorAll(".faq .icon");

  function toggle(index) {
    return function () {
      const faq = faqs[index];
      const icon = icons[index];
      if (faq.classList.contains("active")) {
        icon.innerHTML = '<i class="far fa-plus"></i>';
      } else {
        icon.innerHTML = '<i class="far fa-minus"></i>';
      }
      faq.classList.toggle("active");
    };
  }

  faqs.forEach((faq, index) => {
    faq.addEventListener("click", toggle(index));
  });
});
